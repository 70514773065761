import type { ListProps } from '@chakra-ui/react'
import { List, ListItem, Stack } from '@chakra-ui/react'
import type { CategoryDTO } from 'ecosystem'
import { GrandNextLink } from 'ui'
import type { FC } from 'react'

interface IRecursiveCategoryListProps extends ListProps {
  categories: CategoryDTO[]
  categoriesUrlPath: string
}

const RecursiveCategoryList: FC<IRecursiveCategoryListProps> = ({
  categoriesUrlPath,
  categories,
  ...props
}) => {
  return (
    <List spacing={{ base: 2, lg: 3 }} {...props}>
      {categories.map((category) => (
        <ListItem
          _hover={{
            color: 'text.default'
          }}
          color="text.mild"
          cursor="pointer"
          fontSize={{ base: 'xx-small', sm: 'xs' }}
          key={category.id}
          transition="all 0.1s cubic-bezier(.17,.67,.83,.67)">
          <Stack
            align={{ base: 'flex-start', lg: 'center' }}
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: 1, lg: 2 }}>
            <GrandNextLink
              aria-label={category.name}
              href={`${categoriesUrlPath}/${category.slug}`}>
              {category.name}
            </GrandNextLink>
            {category.subCategories?.length ? (
              <RecursiveCategoryList
                borderLeft="1px solid"
                borderLeftColor="gray.100"
                categories={category.subCategories}
                pl={2}
                spacing={{ base: 1, lg: 2 }}
                {...{ categoriesUrlPath }}
              />
            ) : null}
          </Stack>
        </ListItem>
      ))}
    </List>
  )
}

export default RecursiveCategoryList
