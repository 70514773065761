import type { ErrorRes, Language, RelatedWidget } from 'ecosystem'
import { DynamicWidgetType } from 'ecosystem'
import storefrontApi from 'api/storefront-api'
import type { NextApiRequest, NextApiResponse } from 'next'
import {
  categoryFactory,
  dynamicPageWidgets,
  staticPagesCategoryFactory,
  useLocaleCredentials as setLocaleCredentials
} from 'api'
import { notFound } from 'next/navigation'
import type { StaticPath } from 'shared-utils'

export const getStaticPagesCategoryHandler = async (
  req: NextApiRequest,
  res: NextApiResponse<{ staticPages: RelatedWidget[] } | ErrorRes>
) => {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  if (req.method !== 'GET') {
    res.status(405).json({ error: 'Method not supported.' })
    return
  }

  try {
    const staticPages = await staticPagesCategoryFactory()
    res.status(200).json({ staticPages })
  } catch (error) {
    res.status(404).json({ error: (error as Error).message })
  }
}

export const getStaticPropsHandler = async <T>(
  slug: string,
  priceValues: {
    minPrice: number
    maxPrice: number
  },
  translations?: T
) => {
  const store = await storefrontApi.store.getStore({
    query: ['categories', 'deliveries']
  })
  const { category } = categoryFactory(slug, store.categories || [])

  if (!category) {
    return notFound()
  }

  const filterOptionsResponse = await storefrontApi.store.getFilterOptions({
    categoryIds: [category.id],
    filters: [],
    ...priceValues
  })

  const widgets = await dynamicPageWidgets(
    { id: category.id, type: 'category' },
    DynamicWidgetType.CATEGORY
  )

  const props = {
    ...translations,
    deliveryOptions: store.deliveries || null,
    category,
    widgets,
    filterConfig: {
      options: filterOptionsResponse?.options || [],
      brands: filterOptionsResponse?.brands || []
    }
  }

  return {
    props
  }
}

export const getStaticPathsHandler = async () => {
  const store = await storefrontApi.store.getStore({ query: ['categories'] })
  const mapper = (slug: string): StaticPath => ({ params: { slug } })
  const paths = store.categories
    ?.filter((_c) => _c.slug)
    .map((c) => c.slug)
    .map(mapper)
    .flat()

  return { paths: paths || [], fallback: 'blocking' }
}
