import type { FC, ReactNode } from 'react'
import type { FlexProps } from '@chakra-ui/react'
import { Container } from './Container'

export type LayoutContainerProps = FlexProps & {
  children: ReactNode
}

const LayoutContainer: FC<LayoutContainerProps> = ({ children, ...props }) => {
  return (
    <Container
      height={{
        base: 'auto'
      }}
      {...props}>
      {children}
    </Container>
  )
}

export default LayoutContainer
