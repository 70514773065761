import type { FC } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { Button, ListItem, StackDivider, UnorderedList, VStack } from '@chakra-ui/react'
import GrandText from '../../common/GrandText'
import type { SideNavItem, SideNavSection } from './SideNavigation'

interface ISideNavigationItemsProps extends StackProps {
  navSections: SideNavSection[]
  currentItem: SideNavItem | null
  handleItemClick: (id: number) => void
}

const SideNavigationItems: FC<ISideNavigationItemsProps> = ({
  navSections,
  currentItem,
  handleItemClick,
  ...props
}) => {
  if (!currentItem) {
    return null
  }

  return (
    <VStack align="flex-start" justify="flex-start" {...props}>
      {navSections.map(({ title, items }) => (
        <VStack align="flex-start" divider={<StackDivider />} key={title} w="full">
          <GrandText fontWeight="bold" whiteSpace="nowrap">
            {title}
          </GrandText>

          <UnorderedList spacing={2} styleType="none">
            {items.map((item) => (
              <ListItem fontSize="sm" key={item.id}>
                {!item.component ? (
                  <GrandText color="text.darkGrey" cursor="default">
                    {item.title}
                  </GrandText>
                ) : (
                  <Button
                    aria-label={item.title}
                    bg="transparent"
                    color={currentItem.id === item.id ? 'primary' : 'text.default'}
                    fontWeight={currentItem.id === item.id ? 'bold' : 'normal'}
                    onClick={() => {
                      item.component && handleItemClick(item.id)
                    }}
                    whiteSpace="nowrap">
                    {item.title}
                  </Button>
                )}
              </ListItem>
            ))}
          </UnorderedList>
        </VStack>
      ))}
    </VStack>
  )
}

export default SideNavigationItems
