'use client'
import type { FC, ReactElement } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { Box, HStack, Stack, useDisclosure } from '@chakra-ui/react'
import { BiChevronDown } from 'react-icons/bi'
import GrandPopover from '../../common/GrandPopover'
import GrandText from '../../common/GrandText'
import SideNavigationItems from './SideNavigationItems'

export interface SideNavItem {
  id: number
  title: string
  component?: ReactElement
}

export interface SideNavSection {
  title: string
  items: SideNavItem[]
}

interface ISideNavigationProps {
  navSections: SideNavSection[]
  defaultId?: number
}

const SideNavigation: FC<ISideNavigationProps> = ({ navSections, defaultId }) => {
  const [currentItemId, setCurrentItemId] = useState(navSections[0].items[0].id)

  const { onOpen, onClose, isOpen } = useDisclosure()
  const handleItemClick = (id: number) => {
    setCurrentItemId(id)
    onClose()
  }

  useEffect(() => {
    if (typeof defaultId === 'number') {
      setCurrentItemId(defaultId)
    }
  }, [defaultId])

  const currentItem = useMemo((): SideNavItem | null => {
    let item = null
    navSections.forEach((section) => {
      const obj = section.items.find((i) => i.id === currentItemId)

      if (obj) {
        item = obj
      }
    })

    return item
  }, [currentItemId, navSections])

  return (
    <Stack
      align="flex-start"
      direction={{
        base: 'column',
        md: 'row'
      }}
      h="100%"
      spacing={8}
      w="100%">
      <GrandPopover
        {...{ onClose, isOpen }}
        target={
          <HStack
            bg="background.surface"
            borderRadius="sm"
            cursor="pointer"
            display={{
              base: 'flex',
              md: 'none'
            }}
            justify="space-around"
            onClick={onOpen}
            px={3}
            py={2.25}>
            <GrandText>{currentItem?.title}</GrandText>
            <BiChevronDown />
          </HStack>
        }
        title="">
        <SideNavigationItems {...{ navSections, currentItem, handleItemClick }} />
      </GrandPopover>

      <SideNavigationItems
        display={{ base: 'none', md: 'flex' }}
        w="200px"
        {...{
          navSections,
          currentItem,
          handleItemClick
        }}
      />

      <Box flexGrow={1} w={{ base: '100%', md: '65%' }}>
        {currentItem?.component}
      </Box>
    </Stack>
  )
}

export default SideNavigation
