'use client'
import type { FC } from 'react'
import { useState } from 'react'
import type { FlexProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import type { ImageProps } from 'next/image'
import Image from 'next/image'
import { GrandText, GrandTooltip } from 'ui'
import HoverableComponent from 'ui/common/HoverableComponent'
import type { CategoryDTO } from 'ecosystem'
import { useUIGlobalsContext } from 'ui/lib/ui-globals/hooks'

type IFeaturedCategoryItemProps = FlexProps & {
  item: CategoryDTO
  imageProps?: Partial<ImageProps>
}

const FeaturedCategoryItem: FC<IFeaturedCategoryItemProps> = ({
  item,
  imageProps: { style, ...otherImageProps } = {},
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const { defaultImgSrc } = useUIGlobalsContext()

  return (
    <HoverableComponent
      as={Flex}
      backgroundPosition="center"
      backgroundSize="cover"
      bg="background.overlay"
      borderRadius="sm"
      className="FeaturedCategoryItem"
      color="text.light"
      flexGrow={1}
      onHover={setIsHovered}
      overflow="hidden"
      position="relative"
      {...props}>
      <Box bg="background.overlay" h="100%" position="absolute" w="100%" zIndex={1} />

      <GrandTooltip label={item.name}>
        <Flex
          alignItems="center"
          h="100%"
          justifyContent="center"
          p={2}
          position="absolute"
          w="100%"
          zIndex={2}>
          {isHovered ? (
            <GrandText
              color="text.light"
              cursor={{
                base: 'default',
                md: 'pointer'
              }}
              fontSize="sm"
              noOfLines={4}
              opacity={1}
              px={6}
              textAlign="center"
              transition="all 0.1s cubic-bezier(.17,.67,.83,.67)"
              w="100%">
              {item.thumbnailDescription || item.name}
            </GrandText>
          ) : (
            <GrandText
              fontSize="sm"
              fontWeight="bold"
              isTruncated
              textAlign="center"
              transition="all 0.1s cubic-bezier(.17,.67,.83,.67)"
              w="100%">
              {item.name}
            </GrandText>
          )}
        </Flex>
      </GrandTooltip>

      <Image
        alt="thumbnail"
        fill
        quality={75}
        sizes="16vw"
        src={item.thumbnailUrl || defaultImgSrc}
        style={{
          objectFit: 'cover',
          ...style
        }}
        {...otherImageProps}
      />
    </HoverableComponent>
  )
}

export default FeaturedCategoryItem
