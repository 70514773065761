import type { FC, ReactNode } from 'react'
import { Box, Icon } from '@chakra-ui/react'
import { BiSearch } from 'react-icons/bi'
import GrandIconButton from '../../common/GrandIconButton'

interface LayoutSearchProps {
  children: ReactNode
  onSearch: () => void
}

const LayoutSearch: FC<LayoutSearchProps> = ({ children, onSearch }) => {
  return (
    <>
      <Box display={{ base: 'none', md: 'initial' }}>
        {children}
        <div id="hr-instant-search-results-popper" />
      </Box>
      <Box display={{ base: 'initial', md: 'none' }}>
        <GrandIconButton
          alignItems="center"
          aria-label="search-products"
          bg="none"
          icon={<Icon as={BiSearch} color="text.default" />}
          justifyContent="center"
          onClick={onSearch}
          shadow="none"
          size="sm"
        />
      </Box>
    </>
  )
}

export default LayoutSearch
