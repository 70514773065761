import React, { FC, useState } from 'react'
import { Radio, RadioGroup, Stack, StackProps } from '@chakra-ui/react'
import { FormWrapperCustom } from 'forms'
import type { OnFormSubmit } from 'react-formatge/dist/esm/types'
import { FormFieldType } from 'react-formatge/dist/esm/types'
import { FormSchemaUpdatedDataState } from 'react-formatge/dist/cjs/types'
import { formValidationRgx } from 'react-formatge'
import { rgxExtension } from 'forms/react-formatge-extensions/rgxExtension'
import OptionSelect from 'forms/react-formatge-extensions/OptionSelect'

interface ContactGranditudeFormProps extends StackProps {
  onFormSubmit?: OnFormSubmit<Partial<ContactGranditudeFormType>>
  error?: string
}

export interface ContactGranditudeFormType {
  name: string
  email: string
  phone: string
  message: string
  requestType: null | RequestType
  productType: null | ProductType
}

type RequestType = 'Career' | 'Products' | 'Consultancy'

type ProductType =
  | 'Storefront'
  | 'Reach'
  | 'PIM'
  | 'Connect'
  | 'Price optimization'
  | 'Order management'
  | 'Aurora'

const ContactGranditudeForm: FC<ContactGranditudeFormProps> = ({
  error,
  onFormSubmit,
  ...props
}) => {
  const [selectedRequestId, setSelectedRequestId] = useState<RequestType | null>(null)

  const requests = [
    {
      label: 'Products',
      value: 'Products'
    },
    {
      label: 'Consultancy',
      value: 'Consultancy'
    },
    {
      label: 'Career',
      value: 'Career'
    }
  ]

  const products = [
    {
      label: 'Reach',
      value: 'Reach'
    },
    {
      label: 'Connect',
      value: 'Connect'
    },
    {
      label: 'Storefront',
      value: 'Storefront'
    },
    {
      label: 'PIM',
      value: 'PIM'
    },
    {
      label: 'Price optimization',
      value: 'Price optimization'
    },
    {
      label: 'Order management',
      value: 'Order management'
    },
    {
      label: 'Aurora',
      value: 'Aurora'
    }
  ]

  const inputFields: FormFieldType<ContactGranditudeFormType>[] = [
    {
      name: 'name',
      componentType: 'input',
      label: 'Name',
      placeholder: 'type your name',
      initialValue: '',
      validation: { required: true }
    },
    {
      name: 'email',
      componentType: 'input',
      label: 'Email',
      placeholder: 'type your email',
      initialValue: '',
      validation: {
        required: true,
        validator: {
          regEx: formValidationRgx.email,
          error: 'Invalid email.'
        }
      }
    },
    {
      name: 'phone',
      componentType: 'input',
      label: 'Phone',
      placeholder: 'type your phone',
      initialValue: '',
      validation: {
        validator: {
          regEx: rgxExtension.phoneGeneric,
          error: 'Invalid phone number.'
        }
      }
    },
    {
      name: 'message',
      componentType: 'input',
      label: 'Message',
      placeholder: 'what can we do for you?',
      initialValue: '',
      textarea: true
    },
    {
      name: 'requestType',
      componentType: 'component',
      label: 'Type of request!',
      initialValue: null,
      component: (
        <>
          <div />
          <RadioGroup
            onChange={(v) => setSelectedRequestId(v as RequestType)}
            value={selectedRequestId || ''}>
            <Stack>
              {requests.map(({ value, label }) => (
                <Radio
                  bg="background.surface"
                  colorScheme="brand"
                  key={'requestTypeOption-' + value}
                  {...{ value }}>
                  {label}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </>
      )
    },
    ...(selectedRequestId === 'Products'
      ? [
          {
            name: 'productType' as const,
            label: 'Products',
            componentType: 'component' as const,
            initialValue: null,
            component: <OptionSelect items={[{ label: 'Products..', value: '-' }, ...products]} />
          }
        ]
      : [])
  ]

  const buttonProps = {
    children: 'Send',
    isDisabled: selectedRequestId === null
  }

  const handleOnFormSubmit = async (
    updatedData: Partial<ContactGranditudeFormType>,
    formData: FormSchemaUpdatedDataState<ContactGranditudeFormType>
  ) => {
    const payload = { ...updatedData, requestType: selectedRequestId }
    await onFormSubmit?.(payload)
    formData.name.value = ''
    formData.email.value = ''
    formData.message.value = ''
    formData.phone.value = ''
    setSelectedRequestId(null)
  }

  return (
    <FormWrapperCustom
      onSubmitCb={handleOnFormSubmit}
      {...{ inputFields, buttonProps, error }}
      {...props}
    />
  )
}

export default ContactGranditudeForm
