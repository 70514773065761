import { type FC, useMemo } from 'react'
import { Center, Flex, type FlexProps, HStack } from '@chakra-ui/react'
import { type TopNavPanelItem } from 'ecosystem'
import { ContentContainer, GrandNextLink, GrandText, UIIcons } from '../../common'

const PanelItem = ({ item: { icon, href, label } }: { item: TopNavPanelItem }) => {
  const Icon = useMemo(() => (icon && UIIcons[icon]) || (() => null), [icon])

  return (
    <Center flex={1}>
      <HStack
        {...(href
          ? {
              as: GrandNextLink,
              'aria-label': label,
              href
            }
          : {})}>
        {!!icon && (
          <Flex fontSize="xs">
            <Icon />
          </Flex>
        )}

        <GrandText fontSize="xs" isTruncated w="auto">
          {label}
        </GrandText>
      </HStack>
    </Center>
  )
}

interface IPanel extends FlexProps {
  panelItems: TopNavPanelItem[]
}

const Panel: FC<IPanel> = ({ panelItems, ...props }) => {
  return (
    <Flex
      bg="brand.darkGrey"
      fontSize={{
        base: 'xs',
        md: 'sm'
      }}
      px={{
        base: 2,
        md: 10
      }}
      w="100%"
      {...props}>
      <ContentContainer color="text.light" p={0}>
        {panelItems.map((item) => (
          <PanelItem item={item} key={item.label} />
        ))}
      </ContentContainer>
    </Flex>
  )
}

export default Panel
