'use client'

import { Stack, Wrap, WrapItem } from '@chakra-ui/react'
import type { CategoryDTO } from 'ecosystem'
import type { IGrandSection } from 'ui'
import { GrandNextLink, GrandSection } from 'ui'
import type { FC } from 'react'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import RecursiveCategoryList from './RecursiveCategoryList'

interface IAllCAtegoriesListProps extends IGrandSection {
  categories: CategoryDTO[]
  categoriesUrlPath: string
}

export interface AllCategoriesOverrides {
  allCategoriesTitle?: string
}

const AllCAtegoriesList: FC<IAllCAtegoriesListProps> = ({
  categoriesUrlPath,
  categories,
  ...props
}) => {
  const overrides = useOverridesContext<keyof AllCategoriesOverrides>()
  return (
    <GrandSection title={overrideText('Alla Kategorier', overrides?.allCategoriesTitle)} {...props}>
      <Wrap flex={1} spacing={{ base: 8, lg: 12 }}>
        {categories.map(({ id, name, slug, subCategories }) => (
          <WrapItem key={`${id}-category-list`}>
            <Stack direction="column" spacing={{ base: 1, lg: 2 }}>
              <GrandNextLink
                _hover={{
                  textDecoration: 'underline'
                }}
                aria-label={name}
                cursor="pointer"
                fontSize={{ base: 'xs', sm: 'md' }}
                href={`${categoriesUrlPath}/${slug}`}
                transition="all 0.1s cubic-bezier(.17,.67,.83,.67)">
                {name}
              </GrandNextLink>
              <RecursiveCategoryList {...{ categoriesUrlPath }} categories={subCategories || []} />
            </Stack>
          </WrapItem>
        ))}
      </Wrap>
    </GrandSection>
  )
}

export default AllCAtegoriesList
