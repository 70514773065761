'use client'
import { forwardRef, useState } from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { Flex, HStack } from '@chakra-ui/react'
import { BiChevronDown } from 'react-icons/bi'
import { HoverableComponent, HoverableTextUnderline } from 'ui'

interface IToolbarSubcategoryItemProps extends BoxProps {
  title: string
  onItemClick?: () => void
  hasChildren?: boolean
  noAnimation?: boolean
}

const ToolbarSubcategoryItem = forwardRef<HTMLDivElement, IToolbarSubcategoryItemProps>(
  ({ title, hasChildren, onItemClick, noAnimation, ...props }, ref) => {
    const [isHovered, setIsHovered] = useState(false)

    return (
      <HoverableComponent borderRadius="sm" onHover={setIsHovered} {...props} {...{ ref }}>
        <HStack align="stretch" onClick={onItemClick}>
          <HoverableTextUnderline isHovered={noAnimation ? false : isHovered} text={title} />
          {hasChildren ? (
            <Flex>
              <BiChevronDown />
            </Flex>
          ) : null}
        </HStack>
      </HoverableComponent>
    )
  }
)

ToolbarSubcategoryItem.displayName = 'ToolbarSubcategoryItem'

export default ToolbarSubcategoryItem
