import { useContext } from 'react'
import type { PageContextType } from '../context'
import { PageContext } from '../context'

export const usePageContext = (): PageContextType => {
  const ctx = useContext(PageContext)

  if (!ctx) {
    throw Error('No context provider was found. Wrap your app with the PageContextProvider.')
  }

  return { ...ctx }
}
