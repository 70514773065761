import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'

export const Container = (props: FlexProps) => {
  return (
    <Flex
      alignItems="center"
      color="text.default"
      direction="column"
      justifyContent="flex-start"
      wrap="nowrap"
      {...props}
    />
  )
}
