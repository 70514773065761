import type { FC, ReactElement } from 'react'
import type { FlexProps, StackProps } from '@chakra-ui/react'
import { VStack } from '@chakra-ui/react'
import { type TopNavPanelItem } from 'ecosystem'
import Panel from './Panel'
import type { TopNavContentProps } from './TopNavContent'
import TopNavContent from './TopNavContent'

type ITopNav = StackProps &
  TopNavContentProps & {
    panelItems?: TopNavPanelItem[]
    logo: ReactElement
    panelHeight?: FlexProps['h']
    contentHeight: FlexProps['h']
    isHidden: boolean
    contentContainerProps?: FlexProps
  }

const TopNav: FC<ITopNav> = ({
  panelItems,
  navbarActions,
  menuItems,
  logo,
  panelHeight,
  contentHeight,
  isHidden,
  onMenuToggle,
  toggleIcon,
  onMenuHover,
  contentContainerProps,
  ...props
}) => {
  return (
    <VStack position="fixed" spacing={0} w="100%" zIndex={9000} {...props}>
      {panelItems ? (
        <Panel h={panelHeight ?? 'auto'} transition="all 0.25s ease" {...{ panelItems }} />
      ) : null}
      <TopNavContent
        bg={!isHidden ? 'background.default' : 'background.translucent'}
        h={contentHeight}
        transition="all 0.25s ease"
        {...contentContainerProps}
        {...{
          menuItems,
          navbarActions,
          logo,
          onMenuToggle,
          toggleIcon,
          onMenuHover
        }}
      />
    </VStack>
  )
}

export default TopNav
