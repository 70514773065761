import { useContext } from 'react'
import { CategoryContext } from '../context/CategoryContext'
import type { CategoryContextType } from '../context/types'

export const useCategoryContext = (): CategoryContextType => {
  const ctx = useContext(CategoryContext)

  if (!ctx) {
    throw Error('No context provider was found. Wrap your app with the CategoryContextProvider')
  }

  return { ...ctx }
}
