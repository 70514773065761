'use client'

import { useColorMode } from '@chakra-ui/react'
import { useEffect } from 'react'

interface SetInitialColorModeProps {
  initialColorMode: 'light' | 'dark'
}

// It's useful when we change colorMode in customTheme file, because Chakra saves prev colorMode in LocalStorage and
// overrides a new one every time. To override Chakra behavior, we run setColorMode(initialColorMode)
export const SetInitialColorMode = ({ initialColorMode }: SetInitialColorModeProps) => {
  const { setColorMode } = useColorMode()

  useEffect(() => {
    setColorMode(initialColorMode)
  }, [initialColorMode, setColorMode])

  return null
}
