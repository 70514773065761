import type { FC } from 'react'
import type { LayoutContainerProps } from 'ui/storefront/layout/LayoutContainer'
import { LayoutContainer } from 'ui/storefront'
import type { PageContextType } from '../context'
import { PageContext } from '../context'

type PageLayoutContainerProps = LayoutContainerProps & {
  pageContextProps: PageContextType
}

const PageLayoutContainer: FC<PageLayoutContainerProps> = ({ pageContextProps, ...props }) => {
  return (
    <PageContext.Provider value={pageContextProps}>
      <LayoutContainer {...props} />
    </PageContext.Provider>
  )
}

export default PageLayoutContainer
