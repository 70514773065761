'use client'
import React, { FC, useState } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import ContactGranditudeForm, {
  ContactGranditudeFormType
} from '#/src/custom/forms/ContactGranditudeForm'
import { GrandHeading } from 'ui'
import { FetchError, fetchJson } from 'api'
import { useModal, useSlideOut } from '../controllers'
import { ModalConfirmationProps } from '#/src/custom/controllers/modals/ModalConfirmation'

export interface CustomContactProps {
  containerProps?: BoxProps
}

const CustomContact: FC<CustomContactProps> = ({ containerProps }) => {
  const { openModal } = useModal()
  const { closeSlideOut } = useSlideOut()
  const [errorMsg, setErrorMsg] = useState('')

  const handleFormSubmit = async (data: Partial<ContactGranditudeFormType>) => {
    try {
      const res: { message: string } = await fetchJson('/api/contact/send-contact/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })

      closeSlideOut()
      openModal<ModalConfirmationProps>({
        title: res.message,
        componentId: 'confirmation',
        size: 'md',
        extraArgs: {
          text: 'The message has been sent, we will contact you back as soon as possible.'
        }
      })
      setErrorMsg('')
    } catch (error) {
      if (error instanceof FetchError) {
        setErrorMsg(error.data.message)
      } else {
        // eslint-disable-next-line no-console -- We want to pass the error to console
        console.error('An unexpected error happened:', error)
      }
    }
  }

  return (
    <Box {...containerProps} mx="auto" py={20} w={['xs', 'md', 'xl']}>
      <GrandHeading title="We would love to hear about you" />
      <ContactGranditudeForm
        error={errorMsg}
        mx="auto"
        onFormSubmit={handleFormSubmit}
        w={{ base: '100%' }}
      />
    </Box>
  )
}

export default CustomContact
