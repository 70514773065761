import type { FC } from 'react'
import { useMemo } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { Flex, HStack, VStack } from '@chakra-ui/react'
import type { CategoryDTO } from 'ecosystem'
import type { BreadCrumbItem } from 'ui'
import { EmptyMessage, GrandBreadcrumb } from 'ui'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { SUBCATEGORIES_BEFORE_SHOW_MORE } from '../../constants'
import ToolbarSubcategoryItem from './CategoryToolbar/ToolbarSubcategoryItem'
import ToolbarSubcategory from './CategoryToolbar/ToolbarSubcategory'

interface ISubcategoriesProps extends StackProps {
  breadCrumbs: BreadCrumbItem[]
  subCategories?: CategoryDTO[] | null
  handleMoreButton: () => void
  categoriesUrlPath: string
}

export interface SubcategoriesOverrides {
  subcategoriesShowMoreBtnLabel?: string
  subcategoriesNoCategories?: string
  subcategoriesToolbarTitle?: string
}

const Subcategories: FC<ISubcategoriesProps> = ({
  breadCrumbs,
  subCategories,
  handleMoreButton,
  categoriesUrlPath,
  ...props
}) => {
  const overrides = useOverridesContext<keyof SubcategoriesOverrides>()
  const showMoreButton = useMemo(() => {
    return (
      typeof subCategories?.length === 'number' &&
      subCategories.length > SUBCATEGORIES_BEFORE_SHOW_MORE
    )
  }, [subCategories])

  return (
    <VStack w="100%" {...props}>
      <Flex align="center" flex={1}>
        <GrandBreadcrumb {...{ breadCrumbs }} />
      </Flex>
      <HStack display={['none', 'flex']} spacing={6} w="100%">
        {!subCategories && (
          <EmptyMessage
            text={overrideText('Inga kategorier hittade', overrides?.subcategoriesNoCategories)}
          />
        )}
        {subCategories?.map(
          (subCategory, index) =>
            index <= SUBCATEGORIES_BEFORE_SHOW_MORE - 1 && (
              <ToolbarSubcategory key={subCategory.id} {...{ subCategory, categoriesUrlPath }} />
            )
        )}

        {showMoreButton ? (
          <ToolbarSubcategoryItem
            aria-label={overrideText('Mer', overrides?.subcategoriesShowMoreBtnLabel)}
            hasChildren
            noAnimation
            onItemClick={handleMoreButton}
            title={overrideText('Mer', overrides?.subcategoriesShowMoreBtnLabel)}
            w="auto"
          />
        ) : null}
      </HStack>
      {Boolean(subCategories?.length) && (
        <ToolbarSubcategoryItem
          display={['flex', 'none']}
          hasChildren
          noAnimation
          onItemClick={handleMoreButton}
          title={overrideText('Kategorier', overrides?.subcategoriesToolbarTitle)}
          w="auto"
        />
      )}
    </VStack>
  )
}

export default Subcategories
