import type { FC } from 'react'
import { useMemo } from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { HStack } from '@chakra-ui/react'
import type { CategoryDTO } from 'ecosystem'
import type { BreadCrumbItem } from 'ui'
import { GrandDivider, GrandSection, ImageBox } from 'ui'
import { sortCategories } from 'shared-utils'
import Subcategories from '../Subcategories'

export interface CategoryToolbarProps extends BoxProps {
  onMore: () => void
  categoriesUrlPath: string
  category: CategoryDTO
  breadCrumbs?: BreadCrumbItem[]
  noImage?: boolean
}

const CategoryToolbar: FC<CategoryToolbarProps> = ({
  category,
  breadCrumbs = [],
  noImage,
  onMore,
  categoriesUrlPath,
  ...props
}) => {
  const subCategories = useMemo(() => {
    return sortCategories(category.subCategories || [])
  }, [category])

  return (
    <GrandSection
      contentContainerProps={{ p: 0, px: 6, py: 0, spacing: 4 }}
      py={{
        base: 2,
        lg: 6
      }}
      sectionId="category-toolbar"
      {...props}>
      <HStack align="stretch" p={0} py={0} w="100%">
        {!noImage && (
          <ImageBox
            flexGrow={0}
            fontSize={{
              base: '5vw',
              md: '1vw'
            }}
            h={20}
            imageProps={{
              src: category.thumbnailUrl,
              priority: true
            }}
            p={0}
            w={20}
          />
        )}

        <Subcategories
          align={{ base: 'center', lg: 'flex-start' }}
          flexGrow={1}
          handleMoreButton={onMore}
          px={4}
          {...{ subCategories, breadCrumbs, categoriesUrlPath }}
        />
      </HStack>
      <GrandDivider display={{ base: 'none', sm: 'initial' }} />
    </GrandSection>
  )
}

export default CategoryToolbar
